<mat-icon
  class="info-icon"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="isOpen = !isOpen"
  >info_outline</mat-icon
>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="preferredPositions"
  [cdkConnectedOverlayPanelClass]="['info-overlay']"
  (overlayOutsideClick)="isOpen = false"
>
  <div [innerHTML]="infoText"></div>
</ng-template>
