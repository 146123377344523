import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  SortDirection,
  SORT_DIRECTION_DEFAULT,
} from '@trp/community/interfaces';

export interface SortChangeEvent {
  field: string;
  direction: SortDirection;
}

@Component({
  selector: 'trp-sort-item',
  templateUrl: './sort-item.component.html',
  styleUrls: ['./sort-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortItemComponent {
  @Input()
  field!: string;

  @Input()
  label!: string;

  direction: SortDirection = SORT_DIRECTION_DEFAULT;

  @Output()
  sort = new EventEmitter<SortChangeEvent>();

  constructor(private cf: ChangeDetectorRef) {}

  toggle() {
    this.direction =
      this.direction === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC;
    this.sort.emit({
      field: this.field,
      direction: this.direction,
    });
    this.cf.markForCheck();
  }
}
