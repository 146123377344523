import { Injectable, inject } from '@angular/core';
import { auditTime, delay, map } from 'rxjs/operators';
import { LoaderStore } from './loader.store';

@Injectable({ providedIn: 'root' })
export class LoaderQuery {
  private store = inject(LoaderStore);

  loading$ = this.store
    .select((state) => state.loadCount)
    .pipe(
      map((count) => count > 0),
      auditTime(10),
      delay(0)
    );

  noLoading$ = this.store.select((state) => state.loadCount <= 0);
}
