<div
  *ngFor="
    let category of displayedCategories$ | async;
    let categoryIndex = index
  "
  class="category"
>
  <ng-container *ngIf="category.category">
    <div class="category__heading">
      <h3 class="category__title">
        {{ categoryIndex + 1 }}. {{ category.category.title }}
      </h3>
      <div
        class="category__subtitle"
        *ngIf="category.category.description as description"
      >
        {{ description }}
      </div>
    </div>
    <ul
      class="recommendations"
      [attr.data-test-id]="'category-' + categoryIndex"
    >
      <li
        *ngFor="
          let recommendation of category.recommendations;
          let recommendationIndex = index
        "
        class="recommendations__item"
      >
        <mat-spinner
          *ngIf="isLoadingRecommandation(recommendation); else normal"
          [diameter]="16"
          [attr.data-test-id]="
            'recommendation-checkbox-spinner-' + recommendationIndex
          "
        ></mat-spinner>

        <ng-template #normal>
          <mat-checkbox
            [attr.data-test-id]="
              'recommendation-checkbox-' + recommendationIndex
            "
            [color]="'accent'"
            [checked]="recommendation.done"
            (change)="recommendationToggle.emit(recommendation)"
          ></mat-checkbox>
        </ng-template>

        <a
          [class.done]="recommendation.done"
          [attr.href]="recommendation.resource.url"
          class="recommendation-text"
          target="_blank"
        >
          {{ recommendation.text }}
        </a>

        <a
          [attr.href]="recommendation.resource.url"
          target="_blank"
          class="link-icon"
          ><mat-icon>open_in_new</mat-icon></a
        >
      </li>
    </ul>
  </ng-container>
</div>

<button
  *ngIf="displaySeeMoreButton$ | async"
  mat-button
  mat-flat-button
  (click)="onSeeMore()"
  data-test-id="seeMore"
  class="see-more"
>
  {{ 'coach.recommendationsSeeMoreButton' | tl8 }}
</button>
