import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

export type ReportingClientOptions = Pick<
  Sentry.BrowserOptions,
  'dsn' | 'enabled'
>;

@NgModule()
export class ReportingClientModule {
  static forRoot(
    options: ReportingClientOptions
  ): ModuleWithProviders<ReportingClientModule> {
    Sentry.init({
      ...options,
      environment: window.location.hostname,
    });
    return {
      ngModule: ReportingClientModule,
      providers: [
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            logErrors: true,
          }),
        },
      ],
    };
  }
}
