import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { CollapsibleMenuService } from '@trp/community/shared/data-access-collapsible-menu';
import { UserService } from '@trp/community/user/feature-user';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
} from 'angular-animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'community-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation()],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @HostBinding('class.mat-dark-theme') dark$ = true;
  @HostBinding('class.expanded')
  get expanded() {
    return this._expanded;
  }

  @HostBinding('class.collapsed')
  get collapsed() {
    return !this.expanded;
  }

  userLoaded$ = this.userService.dataLoaded$;
  private _expanded = true;
  private subs: Subscription = new Subscription();
  constructor(
    private userService: UserService,
    public menu: CollapsibleMenuService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.menu.isCollapsed$.subscribe((isCollapsed) => {
        this._expanded = !isCollapsed;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
