import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadMoreButtonComponent } from './load-more-button/load-more-button.component';
import { ResultsLabelComponent } from './results-label/results-label.component';
import { TL8Module } from 'tl8';

@NgModule({
  declarations: [LoadMoreButtonComponent, ResultsLabelComponent],
  imports: [CommonModule, TL8Module],
  exports: [LoadMoreButtonComponent, ResultsLabelComponent],
})
export class PaginationModule {}
