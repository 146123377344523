import {
  ProgramVerification,
  ProgramVerificationStatus,
} from '@trp/community/program-verification/interfaces';
import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';
import { getPropertyVerificationStatus } from './get-property-verification-status';
import {
  ProgramOrSectionType,
  SectionProperties,
} from '@trp/community/interfaces';

export function getSectionVerificationStatus(
  section: ProgramOrSectionType,
  communityInformation: PrivateCommunityInformation,
  verifications: Pick<
    ProgramVerification,
    'property' | 'verifiedValue' | 'programOrSectionType'
  >[],
  properties: SectionProperties<any>[]
): ProgramVerificationStatus {
  const filteredVerifications = verifications.filter(
    (v) => v.programOrSectionType === section
  );

  if (filteredVerifications.length === 0) {
    return 'not-yet-verified';
  }
  const propertyStates = properties.map((property) => ({
    property,
    status: getPropertyVerificationStatus(
      property,
      communityInformation,
      filteredVerifications
    ),
  }));
  if (propertyStates.every(({ status }) => status === 'not-yet-verified')) {
    return 'not-yet-verified';
  }
  if (propertyStates.every(({ status }) => status === 'verified')) {
    return 'verified';
  }
  if (propertyStates.some(({ status }) => status === 'reverification-needed')) {
    return 'reverification-needed';
  }
  return 'partially-verified';
}
