import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://api.' + window.location.hostname,
  reporting: {
    enabled: true,
    dsn: 'https://97ce00e78b614d428d6d6f041ac1c702@o457590.ingest.sentry.io/6634822',
  },
  contactEmail: 'solutionshub@recyclingpartnership.org',
  buildInfo: {
    buildNumber: '$$$$TRP_BUILD_NUMBER$$$$',
    buildDate: '$$$$TRP_BUILD_DATE$$$$',
  },
};
