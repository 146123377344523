import { APP_INITIALIZER } from '@angular/core';
import { UserService } from '@trp/community/user/feature-user';

function loadUser(user: UserService): () => void {
  return () => user.loadUser();
}

export const provideLoadUser = () => [
  {
    provide: APP_INITIALIZER,
    deps: [UserService],
    useFactory: loadUser,
    multi: true,
  },
];
