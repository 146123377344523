import {
  HttpContext,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  LOADER_HTTP_CONTEXT_TOKEN,
  LOADER_HTTP_CONTEXT_TOKEN_DEFAULT,
} from './loader.context';
import {
  LoaderFetchOptions,
  LoaderOptions,
  LOADER_OPTIONS,
} from './loader.options';
import { LoaderService } from './state/loader.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    @Inject(LOADER_OPTIONS) private options: LoaderOptions
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const enableLoading = shouldShowLoader(req, this.options);

    return next.handle(req).pipe(this.loaderService.loadPipe(enableLoading));
  }
}

function shouldShowLoader(req: HttpRequest<any>, options: LoaderOptions) {
  const showLoaderLocal = req.context.get(LOADER_HTTP_CONTEXT_TOKEN);
  const showLoaderGlobal =
    options.fetch[req.method.toLowerCase() as keyof LoaderFetchOptions];

  //if loader was not specified per request, we use default loader config
  if (showLoaderLocal === LOADER_HTTP_CONTEXT_TOKEN_DEFAULT) {
    return showLoaderGlobal;
  }

  //otherwise we return whatever was specified locally
  return showLoaderLocal;
}
