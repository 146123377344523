import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
  TemplateRef,
  inject,
} from '@angular/core';
import {
  AcceptedCommunityItem,
  CommunityProfileSections,
  CommunityProfileWidgetConfig,
} from '@trp/community/interfaces';
import { AcceptedCommunityVerifications } from '@trp/community/program-verification/interfaces';
import { TL8Module } from 'tl8';

function stringTransformer(
  value: number | string | null | undefined
): string | null {
  if (!value) {
    return null;
  }

  return String(value);
}

@Component({
  selector: 'trp-community-widget-row',
  standalone: true,
  templateUrl: './community-widget-row.component.html',
  styleUrls: ['./community-widget-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, TL8Module, NgTemplateOutlet],
})
export class CommunityWidgetRowComponent<
  TSection extends CommunityProfileSections
> {
  elementRef = inject(ElementRef<HTMLElement>);
  renderer2 = inject(Renderer2);

  @Input() title = '';
  @Input() widgetSectionConfig: CommunityProfileWidgetConfig[TSection] | null =
    null;
  @Input() reviewData: AcceptedCommunityVerifications | null = null;
  @Input() footerTemplate: TemplateRef<any> | null = null;
  @Input({ transform: stringTransformer }) rowIdentifier: string | null = null;
  @Input() footerEnabled = true;
  @Input() community: AcceptedCommunityItem | null = null;

  @ContentChild('acceptedMaterialsWidgetRow')
  acceptedMaterialsWidgetRowComponentEl: ElementRef<any> | null = null;

  private _maxColumns = 2;

  get hasAcceptedMaterialsWidget() {
    return !!this.acceptedMaterialsWidgetRowComponentEl;
  }

  get shouldDisplayFooter() {
    return !!this.widgetSectionConfig && this.footerEnabled;
  }

  @Input() set maxColumns(value: number) {
    this._maxColumns = value;
    this.renderer2.setProperty(
      this.elementRef.nativeElement,
      'style',
      `--max-columns: ${this.maxColumns}`
    );
  }

  get maxColumns(): number {
    return this._maxColumns;
  }

  @HostBinding('class')
  @Input()
  presentation: 'none' | 'standout' = 'none';

  getFooterTemplateContext() {
    return {
      $implicit: this.widgetSectionConfig?.propertiesToVerify ?? [],
      section: this.widgetSectionConfig?.section ?? null,
      programId: this.rowIdentifier ?? null,
      hostTemplate: 'widget-row',
      community: this.community,
    };
  }
}
