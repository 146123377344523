import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

export interface LoaderState {
  loadCount: number;
}

export function createInitialState(): LoaderState {
  return {
    loadCount: 0,
  };
}

@Injectable({ providedIn: 'root' })
export class LoaderStore extends ComponentStore<LoaderState> {
  constructor() {
    super(createInitialState());
  }
}
