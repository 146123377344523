import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import {
  FeatureUserConfiguration,
  FEATURE_USER_CONFIGURATION,
} from './feature-user-configuration';
import { WINDOW } from './services/user.service';
import { SignInOrUpComponent } from './sign-in-or-up/sign-in-or-up.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { RouterModule } from '@angular/router';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { TL8Module } from 'tl8';
@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatListModule,
    TL8Module,
  ],
  declarations: [UserInfoComponent, SignInOrUpComponent],
  exports: [UserInfoComponent, SignInOrUpComponent],
})
export class CommunityUserFeatureUserModule {
  static forRoot(
    configuration: FeatureUserConfiguration
  ): ModuleWithProviders<CommunityUserFeatureUserModule> {
    return {
      ngModule: CommunityUserFeatureUserModule,
      providers: [
        {
          provide: FEATURE_USER_CONFIGURATION,
          useValue: configuration,
        },
        {
          provide: WINDOW,
          useValue: window,
        },
      ],
    };
  }
}
