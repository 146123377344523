import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { CommunityWidgetComponent } from '../community-widget';

@Component({
  selector: 'trp-community-widget-content',
  standalone: true,
  imports: [],
  templateUrl: './community-widget-content.component.html',
  styleUrls: ['./community-widget-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityWidgetContentComponent implements OnInit {
  widget = inject(CommunityWidgetComponent, { optional: true });

  ngOnInit(): void {
    if (!this.widget) {
      throw new Error(
        `"trp-community-widget-content" must be contained in "trp-community-widget"`
      );
    }
  }
}
