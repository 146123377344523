import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const prefixedUrl = (() => {
      if (req.url.startsWith('http')) {
        return req.url;
      }
      return environment.apiUrl + req.url;
    })();
    return next.handle(
      req.clone({
        url: prefixedUrl,
        withCredentials: true,
      })
    );
  }
}
