import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

@Directive({
  selector: '[trpContentScrolled]',
  exportAs: 'scroll',
})
export class ContentScrolledDirective implements OnInit {
  constructor(private element: ElementRef) {}
  private scroll$ = new BehaviorSubject(false);

  isScrolled$ = this.scroll$.asObservable().pipe(distinctUntilChanged());

  // Check when user scrolls and determine if element is scrolled
  @HostListener('scroll')
  onScroll() {
    const isScrolled = this.element.nativeElement.scrollTop > 0;
    this.scroll$.next(isScrolled);
  }

  // Check once when component is initialized, to catch when element is already scrolled
  ngOnInit(): void {
    this.onScroll();
  }
}
