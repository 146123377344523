import { inject, Injectable } from '@angular/core';
import { defer, finalize, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { LoaderStore } from './loader.store';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private store = inject(LoaderStore);

  on() {
    this.store.setState((state) => ({
      ...state,
      loadCount: state.loadCount + 1,
    }));
  }

  off() {
    this.store.setState((state) => ({
      ...state,
      loadCount: state.loadCount <= 0 ? 0 : state.loadCount - 1,
    }));
  }

  loadPipe<T>(enable?: boolean) {
    return setSwitch<T>(
      () => {
        this.on();
      },
      () => {
        this.off();
      },
      enable
    );
  }
}

export function setSwitch<T>(
  on: () => void,
  off: () => void,
  enable?: boolean
): MonoTypeOperatorFunction<T> {
  return function <T>(source: Observable<T>) {
    if (!enable) {
      return source;
    }

    return defer(() => {
      on();
      return source.pipe(finalize(() => off()));
    });
  };
}
