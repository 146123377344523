import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb.component';
import { CrumbComponent } from './crumb.component';

@NgModule({
  declarations: [BreadcrumbComponent, CrumbComponent],
  imports: [CommonModule, RouterModule],
  exports: [BreadcrumbComponent, CrumbComponent, RouterModule],
})
export class BreadcrumbModule {}
