<community-verify-email-banner
  *ngIf="verifyEmailNotification$ | async as notification"
  [notification]="notification"
></community-verify-email-banner>

<trp-banner
  *ngIf="emailVerifiedNotification$ | async as notification"
  (dismiss)="notificationFacade.dismissNotification(notification.id)"
  [autoDismiss]="10000"
>
  {{ 'banner.emailVerified.text' | tl8 }}

  <a
    trpBannerAction
    mat-button
    mat-stroked-button
    (click)="notificationFacade.dismissNotification(notification.id)"
    [routerLink]="['/recommendation/access']"
  >
    {{ 'banner.emailVerified.requestAccessToCommunities' | tl8 }}
  </a>
</trp-banner>

<trp-banner
  *ngIf="communityUpdates.approvedAggregate$ | async as aggregate"
  (dismiss)="aggregate.dismiss()"
  [bannerType]="'success'"
>
  <ng-container *ngIf="aggregate.count === 1">{{
    'banner.communityUpdate.approved.one'
      | tl8
        : { communityName: aggregate.notifications[0].content.communityName }
  }}</ng-container>
  <ng-container *ngIf="aggregate.count > 1">{{
    'banner.communityUpdate.approved.plural'
      | tl8 : { numberOfCommunities: aggregate.count }
  }}</ng-container>
  <button
    trpBannerAction
    mat-button
    mat-stroked-button
    [routerLink]="['/recommendation']"
    (click)="aggregate.dismiss()"
  >
    {{ 'banner.communityUpdate.approved.goToProgramCoach' | tl8 }}
  </button>
</trp-banner>

<trp-banner
  *ngIf="communityUpdates.rejectedAggregate$ | async as aggregate"
  (dismiss)="aggregate.dismiss()"
  [bannerType]="'error'"
>
  <ng-container *ngIf="aggregate.count === 1">{{
    'banner.communityUpdate.rejected.one'
      | tl8
        : { communityName: aggregate.notifications[0].content.communityName }
  }}</ng-container>
  <ng-container *ngIf="aggregate.count > 1">{{
    'banner.communityUpdate.rejected.plural'
      | tl8 : { numberOfCommunities: aggregate.count }
  }}</ng-container>
  <a
    trpBannerAction
    mat-button
    mat-stroked-button
    [attr.href]="'mailto:' + contactEmail"
    >{{ 'banner.communityUpdate.rejected.contactUs' | tl8 }}</a
  >
</trp-banner>

<trp-banner
  *ngIf="communityUpdates.submittedAggregate$ | async as aggregate"
  (dismiss)="aggregate.dismiss()"
  [bannerType]="'info'"
  [autoDismiss]="10000"
>
  <ng-container *ngIf="aggregate.count === 1">{{
    'banner.communityUpdate.submitted.one'
      | tl8
        : { communityName: aggregate.notifications[0].content.communityName }
  }}</ng-container>
  <ng-container *ngIf="aggregate.count > 1">{{
    'banner.communityUpdate.submitted.plural'
      | tl8 : { numberOfCommunities: aggregate.count }
  }}</ng-container>
</trp-banner>

<trp-banner
  *ngIf="approvedAggregate$ | async as aggregate"
  (dismiss)="aggregate.dismiss()"
  [autoDismiss]="10000"
>
  <ng-container *ngIf="aggregate.count === 1">{{
    'banner.approved.one'
      | tl8
        : { communityName: aggregate.notifications[0].content.community.name }
  }}</ng-container>
  <ng-container *ngIf="aggregate.count > 1">{{
    'banner.approved.plural' | tl8 : { numberOfCommunities: aggregate.count }
  }}</ng-container>
  <a
    trpBannerAction
    mat-button
    mat-stroked-button
    [routerLink]="['/recommendation']"
    (click)="aggregate.dismiss()"
  >
    {{ 'banner.approved.goToProgramCoach' | tl8 }}
  </a>
</trp-banner>

<trp-banner
  *ngIf="rejectedAggregate$ | async as aggregate"
  (dismiss)="aggregate.dismiss()"
  [bannerType]="'error'"
>
  <ng-container *ngIf="aggregate.count === 1">{{
    'banner.rejected.one'
      | tl8
        : { communityName: aggregate.notifications[0].content.community.name }
  }}</ng-container>
  <ng-container *ngIf="aggregate.count > 1">{{
    'banner.rejected.plural' | tl8 : { numberOfCommunities: aggregate.count }
  }}</ng-container>
</trp-banner>

<trp-banner
  *ngIf="createdAggregate$ | async as aggregate"
  (dismiss)="aggregate.dismiss()"
  [bannerType]="'warning'"
>
  <ng-container *ngIf="aggregate.count === 1">{{
    'banner.created.one'
      | tl8
        : { communityName: aggregate.notifications[0].content.community.name }
  }}</ng-container>
  <ng-container *ngIf="aggregate.count > 1">{{
    'banner.created.plural' | tl8 : { numberOfCommunities: aggregate.count }
  }}</ng-container>
</trp-banner>

<trp-banner
  *ngIf="simpleNotification$ | async as notification"
  [autoDismiss]="1000"
  [bannerType]="notification.type"
>
  {{ notification.message | tl8 }}
</trp-banner>

<trp-banner
  *ngIf="programVerifications.verifiedAggregates$ | async as aggregate"
  (dismiss)="aggregate.dismiss()"
  [autoDismiss]="10000"
  [bannerType]="'success'"
>
  <ng-container *ngIf="aggregate.count === 1">{{
    'banner.programVerification.verified.one'
      | tl8
        : { communityName: aggregate.notifications[0].content.communityName }
  }}</ng-container>
  <ng-container *ngIf="aggregate.count > 1">{{
    'banner.programVerification.verified.plural'
      | tl8 : { numberOfCommunities: aggregate.count }
  }}</ng-container>
</trp-banner>

<trp-banner
  *ngIf="
    programVerifications.reverificationNeededAggregates$ | async as aggregate
  "
  (dismiss)="aggregate.dismiss()"
  [bannerType]="'info'"
>
  <ng-container *ngIf="aggregate.count === 1">{{
    'banner.programVerification.reverificationNeeded.one'
      | tl8
        : { communityName: aggregate.notifications[0].content.communityName }
  }}</ng-container>
  <ng-container *ngIf="aggregate.count > 1">{{
    'banner.programVerification.reverificationNeeded.plural'
      | tl8 : { numberOfCommunities: aggregate.count }
  }}</ng-container>
  <a
    trpBannerAction
    mat-button
    mat-stroked-button
    [routerLink]="['/recommendation']"
    (click)="aggregate.dismiss()"
  >
    {{
      'banner.programVerification.reverificationNeeded.goToRecommendations'
        | tl8
    }}
  </a>
</trp-banner>
