import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';
import { UnifiedCommunityInformation } from './unified-community-information';

export interface CommunityAccessRequestUser {
  email: string;
  id: string;
}

export enum CommunityAccessRequestStatus {
  Pending = 1,
  Rejected = 2,
  Accepted = 3,
}

export class CommunityAccessRequest {
  id!: string;

  community!: {
    communityInformation: UnifiedCommunityInformation<PrivateCommunityInformation>;
  };

  createdAt!: string;

  deletedAt?: string;

  status!: CommunityAccessRequestStatus;

  user!: CommunityAccessRequestUser;
}
