export interface MenuItem {
  link: string;
  text: string;
  icon: string;
  external?: boolean;
  onlyVerified?: boolean;
}

export const MENU_CONFIG: MenuItem[] = [
  { link: 'home', text: 'navigation.home', icon: 'home' },
  { link: 'map', text: 'navigation.recyclingMap', icon: 'map' },
  {
    link: 'recommendation',
    text: 'navigation.recommendations',
    icon: 'diversity_3',
    onlyVerified: true,
  },
  {
    link: 'resource',
    text: 'navigation.resources',
    icon: 'folder_open',
  },
  {
    link: 'https://csbi.thinkific.com/',
    text: 'navigation.courseCatalog',
    icon: 'library_books',
    external: true,
  },
  {
    link: 'glossary',
    text: 'navigation.glossary',
    icon: 'menu_book',
  },
  // NOTE: Disabled until content becomes available
  /* {
    link: 'faq',
    text: 'navigation.faq',
    icon: 'question_answer',
  }, */
];
