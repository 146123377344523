import {
  AcceptedCommunityItem,
  CommunityProfileSections,
  DropoffProperties,
  GeneralInfoProperties,
  MultiFamilyProperties,
  ProgramOrSectionType,
  SingleFamilyProperties,
  VerificationPropertyOfSection,
} from '@trp/community/interfaces';

export type GeneralInfoProgramVerification = {
  programOrSectionType: 'generalInformation';
  id: string;
  property: GeneralInfoProperties;
  verifiedValue: unknown;
  programId: null;
  createdAt: string;
  communityId: string;
  verifiedByUser: {
    email: string;
    name: string;
    company: string;
  };
};

export type SingleFamilyProgramVerification = {
  programOrSectionType: 'singleFamilyPrograms';
  id: string;
  property: SingleFamilyProperties;
  verifiedValue: unknown;
  programId: number;
  createdAt: string;
  communityId: string;
  verifiedByUser: {
    email: string;
    name: string;
    company: string;
  };
};

export type MultiFamilyProgramVerification = {
  programOrSectionType: 'multiFamilyPrograms';
  id: string;
  property: MultiFamilyProperties;
  verifiedValue: unknown;
  programId: number;
  createdAt: string;
  communityId: string;
  verifiedByUser: {
    email: string;
    name: string;
    company: string;
  };
};

export type DropoffProgramVerification = {
  programOrSectionType: 'dropoffPrograms';
  id: string;
  property: DropoffProperties;
  verifiedValue: unknown;
  programId: number;
  createdAt: string;
  communityId: string;
  verifiedByUser: {
    email: string;
    name: string;
    company: string;
  };
};

export type ProgramVerification =
  | GeneralInfoProgramVerification
  | SingleFamilyProgramVerification
  | MultiFamilyProgramVerification
  | DropoffProgramVerification;

export type ProgramVerificationOfSection<
  TSection extends CommunityProfileSections
> = TSection extends 'generalInformation'
  ? GeneralInfoProgramVerification
  : TSection extends 'singleFamilyPrograms'
  ? SingleFamilyProgramVerification
  : TSection extends 'multiFamilyPrograms'
  ? MultiFamilyProgramVerification
  : TSection extends 'dropoffPrograms'
  ? DropoffProgramVerification
  : ProgramVerification;

export type PropertyVerificationStatus =
  | 'not-yet-verified'
  | 'verified'
  | 'reverification-needed';
export type ProgramVerificationStatus =
  | 'not-yet-verified'
  | 'verified'
  | 'reverification-needed'
  | 'partially-verified';

export const PROGRAM_VERIFICATION_NOTIFICATIONS = {
  reverificationNeeded: 'program-verification.reverification-needed',
  programVerified: 'program-verification.program-verified',
};

export interface ProgramVerificationReverificationNeededContent {
  communityId: string;
  communityName: string;
}

export interface ProgramVerificationProgramVerifiedContent {
  communityId: string;
  communityName: string;
}

export type CreatePropertyVerificationBody =
  | {
      programOrSectionType: 'generalInformation';
      property: GeneralInfoProperties;
      communityId: string;
      value: unknown;
      // NOTE: When `null` the verification information refers to the general info section
      programId: null;
    }
  | {
      programOrSectionType: 'singleFamilyPrograms';
      property: SingleFamilyProperties;
      communityId: string;
      value: unknown;
      programId: number;
    }
  | {
      programOrSectionType: 'multiFamilyPrograms';
      property: MultiFamilyProperties;
      communityId: string;
      value: unknown;
      programId: number;
    }
  | {
      programOrSectionType: 'dropoffPrograms';
      property: DropoffProperties;
      communityId: string;
      value: unknown;
      programId: number;
    };

export type CreateSectionPropertiesVerificationBody<
  TSection extends ProgramOrSectionType | unknown = unknown
> = {
  programOrSectionType: TSection extends ProgramOrSectionType
    ? TSection
    : ProgramOrSectionType;
  properties: {
    property: VerificationPropertyOfSection<TSection>;
    value: unknown;
  }[];
  communityId: string;
  communityInfoId: string;
  // NOTE: When `null` the verification information refers to the general info section
  programId: number | null;
};

export interface CommunityWithVerifications {
  id: string;
  communityInformationId: string;
  name: string;
  communityVerificationStatus: ProgramVerificationStatus;
  verifications: ProgramVerification[];
}

export interface ProgramOrSectionTypeWithVerifications {
  programId: number | null;
  programOrSectionType: ProgramOrSectionType;
  communityId: string;
  communityName: string;
  communityVerificationStatus: ProgramVerificationStatus;
  verifications: ProgramVerification[];
}

export interface NoInfoBody {
  communityId: string;
  section: string;
}

export type ProgramVerificationPublicStatus =
  | {
      verified: true;
      verifiedAt: string;
      verifiedBy: string;
    }
  | {
      verified: false;
    };

export interface AcceptedCommunityVerifications {
  community: AcceptedCommunityItem;
  verifications: ProgramVerification[];
}
