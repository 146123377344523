import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeadingComponent } from './page-heading.component';
import { SharedUiPageTitleModule } from '@trp/shared/ui/page-title';

@NgModule({
  declarations: [PageHeadingComponent],
  imports: [CommonModule, SharedUiPageTitleModule],
  exports: [PageHeadingComponent, SharedUiPageTitleModule],
})
export class PageHeadingModule {}
