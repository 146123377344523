import { ConnectedPosition } from '@angular/cdk/overlay';

export const left: ConnectedPosition = {
  originX: 'start',
  originY: 'top',
  overlayX: 'end',
  overlayY: 'center',
};

export const top: ConnectedPosition = {
  originX: 'center',
  originY: 'top',
  overlayX: 'center',
  overlayY: 'bottom',
};

export const topLeft: ConnectedPosition = {
  originX: 'center',
  originY: 'top',
  overlayX: 'end',
  overlayY: 'bottom',
};

export const bottom: ConnectedPosition = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'center',
  overlayY: 'top',
};

export const bottomRight: ConnectedPosition = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'start',
  overlayY: 'top',
};

export const bottomLeft: ConnectedPosition = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'end',
  overlayY: 'top',
};

export const defaultPreferredPositions: ConnectedPosition[] = [
  top,
  topLeft,
  bottom,
  bottomLeft,
  bottomRight,
  left,
];
