import { Component } from '@angular/core';
import { UserService } from '@trp/community/user/feature-user';
import { startWith } from 'rxjs';
import { MenuItem, MENU_CONFIG } from '../../menu.config';

@Component({
  selector: 'community-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  items = MENU_CONFIG;
  verified$ = this.userService.isVerifiedUser$.pipe(startWith(false));

  constructor(private userService: UserService) {}

  getSidebarLinkClass(item: MenuItem) {
    if (item.external) {
      return ['sidebar-link', 'external-link'];
    }

    return ['sidebar-link'];
  }
}
