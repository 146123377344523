import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableGtagOnProdOnly } from '@trp/shared/gtag';
import Plausible from 'plausible-tracker';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  const plausible = Plausible({
    domain: window.location.hostname,
    trackLocalhost: false,
  });

  plausible.enableAutoPageviews();

  enableGtagOnProdOnly({
    onlyForHostname: 'community.recyclingpartnership.org',
    ids: {
      'gtag/js': 'G-S2EHPJM92T',
      'gtm.js': 'GTM-P6V874W',
    },
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
