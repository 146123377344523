export const NOTIFICATIONS = {
  verifyEmail: 'user.verifyEmail',
  emailVerified: 'user.emailVerified',
};

export interface VerifyEmailNotificationContent {
  email: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmailVerifiedNotificationContent {}
