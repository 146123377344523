import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationFacade } from '@trp/community/notification/client-data-access';
import { CollapsibleMenuService } from '@trp/community/shared/data-access-collapsible-menu';
import { UserService } from '@trp/community/user/feature-user';
import { take, tap } from 'rxjs';

@Component({
  selector: 'community-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public notificationFacade: NotificationFacade,
    public user: UserService,
    public menu: CollapsibleMenuService,
    private title: Title,
    private translateService: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.translateService
      .stream('toolTitle')
      .pipe(
        take(1),
        tap((text) => this.title.setTitle(text))
      )
      .subscribe();
    this.iconRegistry.addSvgIconResolver((name, namespace) => {
      if (namespace === 'custom-solid') {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          window.location.origin +
            '/assets/icons/CommunityTool_MicroIcons_Solid_v2/' +
            name +
            '_Solid.svg'
        );
      }
      if (namespace === 'custom-light') {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          window.location.origin +
            '/assets/icons/CommunityTool_MicroIcons_Light_v2/' +
            name +
            '_Light.svg'
        );
      }

      if (namespace === 'global') {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          window.location.origin + '/assets/icons/' + name + '.svg'
        );
      }

      return null;
    });
  }
}
