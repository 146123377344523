<trp-base-layout
  [fitContent]="true"
  [collapsedMenu]="menu.isCollapsed$ | async"
>
  <community-topbar
    *ngIf="(user.isSignedIn$ | async) === false"
    trp-layout-topbar
  ></community-topbar>
  <community-sidebar
    *ngIf="(user.isSignedIn$ | async) === true"
    trp-layout-sidebar
  ></community-sidebar>
  <div class="content-wrapper" trp-layout-content>
    <community-banner-container
      *ngIf="notificationFacade.hasNotifications$ | async"
      class="mat-elevation-z6"
    ></community-banner-container>

    <div class="routed-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</trp-base-layout>

<trp-global-loader></trp-global-loader>
