import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommunityInfoIconComponent } from '@trp/community/shared/ui';
import { CommunityWidgetComponent } from '../community-widget';

@Component({
  selector: 'trp-community-widget-header',
  standalone: true,
  imports: [MatIconModule, CommunityInfoIconComponent, NgIf],
  templateUrl: './community-widget-header.component.html',
  styleUrls: ['./community-widget-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityWidgetHeaderComponent implements OnInit {
  widget = inject(CommunityWidgetComponent, { optional: true });

  @Input() title = '';
  @Input() icon = '';
  @Input() infoText = '';

  ngOnInit(): void {
    if (!this.widget) {
      throw new Error(
        `"trp-community-widget-header" must be contained in "trp-community-widget"`
      );
    }
  }
}
