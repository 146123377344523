import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortItemComponent } from './sort-item/sort-item.component';
import { TL8Module } from 'tl8';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SortItemComponent],
  imports: [CommonModule, TL8Module, MatIconModule],
  exports: [SortItemComponent],
})
export class SortModule {}
