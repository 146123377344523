import { Component } from '@angular/core';
import { LoaderQuery } from '../state/loader.query';

@Component({
  selector: 'trp-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
})
export class GlobalLoaderComponent {
  loading$ = this.loaderQuery.loading$;

  constructor(private loaderQuery: LoaderQuery) {}
}
