import { Component } from '@angular/core';
import {
  AccessRequestApprovedNotificationContent,
  AccessRequestCreatedNotificationContent,
  AccessRequestRejectedNotificationContent,
  NOTIFICATIONS as ACCESS_NOTIFICATIONS,
} from '@trp/community/access/interfaces';
import {
  NOTIFICATIONS as COMMUNITY_UPDATE_NOTIFICATIONS,
  SubmissionAcceptedNotificationContent,
  SubmissionRejectedNotificationContent,
  SubmissionSubmittedNotificationContent,
} from '@trp/community/community-update/interfaces';
import { NotificationFacade } from '@trp/community/notification/client-data-access';
import {
  EmailVerifiedNotificationContent,
  NOTIFICATIONS as USER_NOTIFICATIONS,
  VerifyEmailNotificationContent,
} from '@trp/community/user/interfaces';
import { environment } from '../../../environments/environment';
import {
  PROGRAM_VERIFICATION_NOTIFICATIONS,
  ProgramVerificationProgramVerifiedContent,
  ProgramVerificationReverificationNeededContent,
} from '@trp/community/program-verification/interfaces';

@Component({
  selector: 'community-banner-container',
  templateUrl: './banner-container.component.html',
  styleUrls: ['./banner-container.component.scss'],
})
export class BannerContainerComponent {
  contactEmail = environment.contactEmail;

  approvedAggregate$ =
    this.notificationFacade.getDismissableAggregate$<AccessRequestApprovedNotificationContent>(
      ACCESS_NOTIFICATIONS.accessRequestApproved
    );

  rejectedAggregate$ =
    this.notificationFacade.getDismissableAggregate$<AccessRequestRejectedNotificationContent>(
      ACCESS_NOTIFICATIONS.accessRequestRejected
    );

  createdAggregate$ =
    this.notificationFacade.getDismissableAggregate$<AccessRequestCreatedNotificationContent>(
      ACCESS_NOTIFICATIONS.accessRequestCreated
    );

  communityUpdates = {
    approvedAggregate$:
      this.notificationFacade.getDismissableAggregate$<SubmissionAcceptedNotificationContent>(
        COMMUNITY_UPDATE_NOTIFICATIONS.submissionAccepted
      ),

    rejectedAggregate$:
      this.notificationFacade.getDismissableAggregate$<SubmissionRejectedNotificationContent>(
        COMMUNITY_UPDATE_NOTIFICATIONS.submissionRejected
      ),

    submittedAggregate$:
      this.notificationFacade.getDismissableAggregate$<SubmissionSubmittedNotificationContent>(
        COMMUNITY_UPDATE_NOTIFICATIONS.submissionSubmitted
      ),
  };

  programVerifications = {
    verifiedAggregates$:
      this.notificationFacade.getDismissableAggregate$<ProgramVerificationProgramVerifiedContent>(
        PROGRAM_VERIFICATION_NOTIFICATIONS.programVerified
      ),
    reverificationNeededAggregates$:
      this.notificationFacade.getDismissableAggregate$<ProgramVerificationReverificationNeededContent>(
        PROGRAM_VERIFICATION_NOTIFICATIONS.reverificationNeeded
      ),
  };

  verifyEmailNotification$ =
    this.notificationFacade.getOneNotificationOfType$<VerifyEmailNotificationContent>(
      USER_NOTIFICATIONS.verifyEmail
    );

  emailVerifiedNotification$ =
    this.notificationFacade.getOneNotificationOfType$<EmailVerifiedNotificationContent>(
      USER_NOTIFICATIONS.emailVerified
    );

  simpleNotification$ = this.notificationFacade.simpleNotification$;

  constructor(public notificationFacade: NotificationFacade) {}
}
