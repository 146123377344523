<mat-expansion-panel [hideToggle]="false" [togglePosition]="'before'">
  <mat-expansion-panel-header>
    <div class="card-header">
      <div class="card-title">
        <ng-content select="[card-title]"></ng-content>
      </div>
      <div class="card-actions">
        <ng-content select="[card-actions]"></ng-content>
      </div>
      <div class="card-status">
        <ng-content select="[card-status]"></ng-content>
      </div>
    </div>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <section class="card-content">
      <ng-content select="[card-content]"></ng-content>
    </section>
  </ng-template>
</mat-expansion-panel>
