import { Injectable, OnDestroy } from '@angular/core';
import { Notification } from '@trp/community/notification/interfaces';
import {
  catchError,
  combineLatest,
  interval,
  NEVER,
  Observable,
  pipe,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import { ComponentStore } from '@ngrx/component-store';
import { NotificationApiClient } from './notification-api.client';
import { UserService } from '@trp/community/user/feature-user';

const NOTIFICATION_FETCH_INTERVAL = 60_000;

interface NotificationStoreState {
  notifications: Notification<unknown>[];
  dismissingNotificationIds: Set<Notification['id']>;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationStore implements OnDestroy {
  private state = new ComponentStore<NotificationStoreState>({
    notifications: [],
    dismissingNotificationIds: new Set(),
  });

  notifications$ = this.state.select(
    ({ notifications, dismissingNotificationIds }) => {
      return notifications.filter(
        ({ id }) => !dismissingNotificationIds.has(id)
      );
    }
  );

  triggerFetch = this.state.effect<void>(pipe(this.fetchNotifications()));

  private fetchInterval = this.state.effect<void>(
    pipe(
      switchMap(() =>
        this.userService.isSignedIn$.pipe(
          switchMap((isSignedIn) => {
            if (!isSignedIn) {
              this.setNotifications([]);
              return NEVER;
            }
            return interval(NOTIFICATION_FETCH_INTERVAL).pipe(
              startWith(0),
              this.fetchNotifications()
            );
          })
        )
      )
    )
  );

  private setNotifications = this.state.updater(
    (state, notifications: Notification<unknown>[]) => ({
      ...state,
      notifications,
    })
  );

  private addNotificationIdsToDismissingList = this.state.updater(
    (state, notificationIds: Notification['id'][]) => {
      const dismissingNotificationIds = new Set(
        state.dismissingNotificationIds
      );
      notificationIds.forEach((notificationId) =>
        dismissingNotificationIds.add(notificationId)
      );
      return {
        ...state,
        dismissingNotificationIds,
      };
    }
  );

  private removeNotifications = this.state.updater(
    (state, notificationIds: Notification['id'][]) => {
      const dismissingNotificationIds = new Set(
        state.dismissingNotificationIds
      );
      notificationIds.forEach((notificationId) =>
        dismissingNotificationIds.delete(notificationId)
      );
      const notifications = state.notifications.filter(
        ({ id }) => !notificationIds.includes(id)
      );
      return {
        ...state,
        notifications,
        dismissingNotificationIds,
      };
    }
  );

  constructor(
    private readonly notificationApiClient: NotificationApiClient,
    private readonly userService: UserService
  ) {
    this.fetchInterval();
  }

  dismissNotifications = this.state.effect(
    (notificationIds$: Observable<Notification['id'][]>) => {
      return notificationIds$.pipe(
        tap((notificationIds) =>
          this.addNotificationIdsToDismissingList(notificationIds)
        ),
        switchMap((notificationIds) => {
          return combineLatest(
            notificationIds.map((notificationId) =>
              this.notificationApiClient.dismissNotification(notificationId)
            )
          ).pipe(
            tap(() => this.removeNotifications(notificationIds)),
            catchError(() => {
              this.removeNotifications(notificationIds);
              return NEVER;
            })
          );
        })
      );
    }
  );

  ngOnDestroy() {
    this.state.ngOnDestroy();
  }

  private fetchNotifications<T>() {
    return (source: Observable<T>): Observable<Notification<unknown>[]> => {
      return source.pipe(
        switchMap(() =>
          this.notificationApiClient.getAll().pipe(
            tap((notifications) => this.setNotifications(notifications)),
            catchError(() => NEVER)
          )
        )
      );
    };
  }
}
