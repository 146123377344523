import {
  ProgramVerification,
  PropertyVerificationStatus,
} from '@trp/community/program-verification/interfaces';
import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';
import { deepEqual } from './deep-equal';
import { getPropertyValue } from './get-verification-property-value';
import { SectionProperties } from '@trp/community/interfaces';

// TODO: Specify the verification group
export function getPropertyVerificationStatus(
  property: SectionProperties<any>,
  communityInformation: PrivateCommunityInformation,
  verifications: Pick<
    ProgramVerification,
    'property' | 'verifiedValue' | 'programOrSectionType'
  >[]
): PropertyVerificationStatus {
  const verification = verifications.find((v) => v.property === property);
  if (!verification) {
    return 'not-yet-verified';
  }

  const propertyValue = getPropertyValue(
    communityInformation,
    property,
    verification.programOrSectionType
  );

  if (verification && deepEqual(verification.verifiedValue, propertyValue)) {
    return 'verified';
  }

  return 'reverification-needed';
}
