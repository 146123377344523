import { InjectionToken } from '@angular/core';
import { BuildInformation } from '@trp/deployment-check/interfaces';

export interface DeploymentCheckUiConfig {
  buildInfo: BuildInformation;
  apiPath: string;
}

export const DEPLOYMENT_CHECK_UI_CONFIG = new InjectionToken(
  'DEPLOYMENT_CHECK_UI_CONFIG'
);
