import { Directive } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[trpBannerAction], a[trpBannerAction]',
  standalone: true,
})
export class BannerActionDirective {}

@Directive({
  // eslint-disable-next-line @angular-eslint/component-selector, @angular-eslint/directive-selector
  selector:
    '[trpBannerAction]:not([mat-stroked-button]), [trpBannerAction]:not([mat-button])',
  standalone: true,
})
export class BannerActionWithoutButtonDirective {
  constructor() {
    console.error(
      'The banner action expects to be associated with a mat-button and mat-stroked-button'
    );
  }
}
