import { HttpClient } from '@angular/common/http';
import { Component, Input, TemplateRef } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Notification } from '@trp/community/notification/interfaces';
import { VerifyEmailNotificationContent } from '@trp/community/user/interfaces';

@Component({
  selector: 'community-verify-email-banner',
  templateUrl: './verify-email-banner.component.html',
  styleUrls: ['./verify-email-banner.component.scss'],
})
export class VerifyEmailBannerComponent {
  @Input() notification?: Notification<VerifyEmailNotificationContent>;

  state: 'idle' | 'pending' = 'idle';

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) {}

  sendVerificationEmail(templates: {
    error: TemplateRef<unknown>;
    success: TemplateRef<unknown>;
  }) {
    this.state = 'pending';
    this.httpClient.post('/auth/send_verification_email', {}).subscribe({
      next: () => {
        this.state = 'idle';
        this.snackBar.openFromTemplate(templates.success);
      },
      error: () => {
        this.state = 'idle';
        this.snackBar.openFromTemplate(templates.error);
      },
    });
  }
}
