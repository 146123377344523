<a
  *ngFor="let item of items"
  [communityMenuItem]="item"
  [itemActiveClass]="'active-link'"
  mat-ripple
  [class]="getSidebarLinkClass(item)"
  [class.disabled]="item.onlyVerified && (verified$ | async) === false"
  [attr.aria-disabled]="item.onlyVerified && (verified$ | async) === false"
  [attr.data-test-id]="'navigation-' + item.link"
>
  <mat-icon>{{ item.icon }}</mat-icon>
  <span> {{ item.text | tl8 }}</span>
</a>
