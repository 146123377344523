import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { BannerComponent } from './components/banner/banner.component';
import {
  BannerActionDirective,
  BannerActionWithoutButtonDirective,
} from './directives/banner-action/banner-action.directive';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    BannerActionWithoutButtonDirective,
    BannerActionDirective,
  ],
  declarations: [BannerComponent],
  exports: [
    BannerComponent,
    BannerActionWithoutButtonDirective,
    BannerActionDirective,
  ],
})
export class BannerModule {}
