import { Pipe, PipeTransform } from '@angular/core';
import { CommunityInformation } from '@trp/nationaldb/interfaces';

@Pipe({ name: 'curbsideContamination', standalone: true })
export class CurbsideContaminationPipe implements PipeTransform {
  transform(
    value: CommunityInformation['contaminationControls'] | null | undefined
  ) {
    if (!value || value.length === 0) {
      return '';
    }

    let html = `<ul class="bullet-list">`;
    value.forEach((item) => {
      html += `<li>${item}</li>`;
    });
    html += `</ul>`;

    return html;
  }
}
