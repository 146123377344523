import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TL8Module } from 'tl8';
import { StatusIndicatorType } from './status-indicator.type';

@Component({
  selector: 'trp-status-indicator',
  standalone: true,
  imports: [TL8Module, FontAwesomeModule],
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent {
  @Input({ required: true })
  @HostBinding('class')
  type: StatusIndicatorType = 'none';
}
