import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { defaultPreferredPositions } from './default-preferred-positions';

@Component({
  selector: 'trp-community-info-icon',
  templateUrl: './community-info-icon.component.html',
  styleUrls: ['./community-info-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OverlayModule, MatIconModule],
})
export class CommunityInfoIconComponent {
  @Input() infoText = '';

  isOpen = false;
  preferredPositions = defaultPreferredPositions;
}
