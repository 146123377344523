import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TL8Module } from 'tl8';
import { SearchItemComponent } from './search-item.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [SearchItemComponent],
  imports: [CommonModule, TL8Module, PipesModule],
  exports: [SearchItemComponent],
})
export class SearchItemModule {}
