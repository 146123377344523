import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// here we are specific: what we need is only a subset of what the consumer needs.
// That way we can decouple and extract as library later
interface InternalMenuItem {
  external?: boolean;
  link: string;
}

@Directive({
  selector: '[communityMenuItem]',
})
export class MenuItemDirective implements OnDestroy {
  @Input() communityMenuItem!: InternalMenuItem;
  @Input() itemActiveClass = '';

  private subscription: Subscription;

  constructor(private router: Router, private readonly cdr: ChangeDetectorRef) {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.cdr.markForCheck();
      }
    });
  }

  @HostBinding('attr.target')
  get target() {
    if (this.communityMenuItem.external) {
      return '_blank';
    }
  }

  @HostBinding('attr.href')
  get link() {
    const urlTree = this.getUrlTree();

    if (this.communityMenuItem.external) {
      return this.communityMenuItem.link;
    }

    if (!urlTree) {
      return;
    }
    return urlTree.toString();
  }

  @HostListener('click', [])
  navigateTo() {
    const urlTree = this.getUrlTree();
    if (!urlTree) {
      return;
    }
    this.router.navigateByUrl(urlTree);
    return false;
  }

  @HostBinding('class')
  get isLinkActive() {
    const urlTree = this.getUrlTree();
    if (!urlTree) {
      return {};
    }

    return {
      [this.itemActiveClass]: this.router.isActive(urlTree, false),
    };
  }

  private getUrlTree() {
    if (this.communityMenuItem.external) {
      return null;
    }
    return this.router.createUrlTree(
      typeof this.communityMenuItem.link === 'string'
        ? [this.communityMenuItem.link]
        : this.communityMenuItem.link
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
