import { Component } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'trp-sign-in-or-up',
  templateUrl: './sign-in-or-up.component.html',
  styleUrls: ['./sign-in-or-up.component.scss'],
})
export class SignInOrUpComponent {
  loginUrl = this.userService.loginUrl;

  constructor(private userService: UserService) {}
}
