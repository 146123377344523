import { ProgramVerification } from '@trp/community/program-verification/interfaces';
import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';
import { getSectionVerificationStatus } from './get-section-verification-status';
import {
  ProgramOrSectionType,
  SectionProperties,
} from '@trp/community/interfaces';

export interface VerifiedByInfo {
  verifiedBy: ProgramVerification['verifiedByUser'];
  verifiedAt: ProgramVerification['createdAt'];
}

export function getSectionVerifiedByInformation<
  T extends Pick<
    ProgramVerification,
    | 'property'
    | 'verifiedValue'
    | 'createdAt'
    | 'verifiedByUser'
    | 'programOrSectionType'
  > = ProgramVerification
>(
  section: ProgramOrSectionType,
  communityInformation: PrivateCommunityInformation,
  verifications: T[],
  properties: SectionProperties<any>[]
): VerifiedByInfo | null {
  const isVerified =
    getSectionVerificationStatus(
      section,
      communityInformation,
      verifications,
      properties
    ) === 'verified';
  if (!isVerified) {
    return null;
  }
  const latestVerification = verifications
    .filter((v) => properties.includes(v.property))
    .reduce((prev, curr) => {
      if (!prev) {
        return curr;
      }
      if (new Date(prev.createdAt) < new Date(curr.createdAt)) {
        return curr;
      }
      return prev;
    }, null as T | null);
  if (!latestVerification) {
    return null;
  }
  return {
    verifiedBy: latestVerification.verifiedByUser,
    verifiedAt: latestVerification.createdAt,
  };
}
