import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'trp-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent {
  @Input()
  fitContent = false;

  @Input()
  @HostBinding('class.compact')
  collapsedMenu: boolean | null = false;
}
