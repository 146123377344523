<header class="header">
  <a class="logo" [routerLink]="['/']"></a>
</header>
<nav>
  <ng-container *ngFor="let item of items">
    <a
      [communityMenuItem]="item"
      [itemActiveClass]="'active-link'"
      mat-ripple
      [attr.data-test-id]="'navigation-' + item.link"
      [ngClass]="getNavLinkClass(item)"
    >
      <span> {{ item.text | tl8 }}</span>
    </a>
  </ng-container>
</nav>
<div class="spacer"></div>

<trp-sign-in-or-up></trp-sign-in-or-up>
