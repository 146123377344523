import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root',
})
export class VerifiedUserGuard {
  constructor(private user: UserService) {}
  canActivate(): Observable<boolean> {
    return this.user.isVerifiedUser$;
  }
}

export const isVerifiedUser: CanActivateFn = () =>
  inject(VerifiedUserGuard).canActivate();
