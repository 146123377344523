import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import {
  Recommendation,
  ResultDetailCategory,
} from '@trp/community/interfaces';
import {
  ExternalResourceType,
  LocalResourceType,
} from '@trp/nationaldb/interfaces';
import { TL8Module } from 'tl8';

@Component({
  selector: 'trp-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatLegacyProgressSpinnerModule,
    MatLegacyCheckboxModule,
    MatLegacyButtonModule,
    RouterModule,
    MatIconModule,
    TL8Module,
  ],
})
export class RecommendationsComponent extends ComponentStore<{
  seeMore: boolean;
  categories: ResultDetailCategory[];
}> {
  @Input() set categories(categories: ResultDetailCategory[]) {
    this.patchState({ categories });
  }

  @Output()
  recommendationToggle = new EventEmitter<Recommendation>();

  @Input()
  recommendationToggleLoading: Array<Recommendation['id']> | null = [];

  displayedCategories$ = this.select((s) =>
    s.seeMore ? s.categories : s.categories.slice(0, 3)
  );
  displaySeeMoreButton$ = this.select(
    (s) => !s.seeMore && s.categories.length > 3
  );
  onSeeMore = this.updater((state) => ({ ...state, seeMore: true }));

  constructor() {
    super({ seeMore: false, categories: [] });
  }

  isLoadingRecommandation(recommendation: Recommendation) {
    return (
      this.recommendationToggleLoading &&
      this.recommendationToggleLoading.includes(recommendation.id)
    );
  }

  isLocalResourceType(
    resource: LocalResourceType | ExternalResourceType
  ): resource is LocalResourceType {
    return !!(resource?.resourceType && resource.resourceType === 'local');
  }

  isExternalResourceType(
    resource: LocalResourceType | ExternalResourceType
  ): resource is LocalResourceType {
    return !!(resource?.resourceType && resource.resourceType === 'external');
  }

  getResourceLink(resource: LocalResourceType | ExternalResourceType) {
    if (resource.resourceType === 'local') {
      return ['/resource', resource.type + ':' + resource.id];
    }

    return resource.url;
  }
}
