import { CommunityInfoSubmissionPayload } from '@trp/nationaldb/interfaces';

export const NOTIFICATIONS = {
  submissionAccepted: 'submission.accepted',
  submissionRejected: 'submission.rejected',
  submissionSubmitted: 'submission.submitted',
};

export interface SubmissionSubmittedNotificationContent {
  communityId: string;
  communityName: string;
}

export interface SubmissionAcceptedNotificationContent {
  communityId: string;
  communityName: string;
}

export interface SubmissionRejectedNotificationContent {
  communityId: string;
  communityName: string;
}

export type SubmissionPayload = Omit<
  CommunityInfoSubmissionPayload,
  'requestedBy' | 'communityId'
>;
