import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  fromEvent,
  map,
  merge,
  NEVER,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
  timer,
} from 'rxjs';

@Component({
  selector: 'trp-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  @HostBinding('class')
  @Input()
  bannerType: 'neutral' | 'warning' | 'error' | 'info' | 'success' = 'neutral';
  @Input() dismissable = true;
  @Input() set autoDismiss(autoDismiss: number | false) {
    this.autoDismissSubject.next(autoDismiss);
  }
  @Output() dismiss = new EventEmitter();

  private autoDismissSubject = new BehaviorSubject<number | false>(false);
  withCounting$ = this.autoDismissSubject.pipe(
    map((autoDismiss) => autoDismiss !== false)
  );
  transitionDuration$ = this.autoDismissSubject.pipe(
    filter((autoDismiss): autoDismiss is number => autoDismiss !== false)
  );
  animating$ = this.autoDismissSubject.pipe(
    switchMap((autoDismiss) => {
      if (autoDismiss === false) {
        return of(false);
      }
      return merge(
        fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(
          startWith({}),
          map(() => 'leave')
        ),
        fromEvent(this.elementRef.nativeElement, 'mouseenter').pipe(
          map(() => 'enter')
        )
      ).pipe(
        switchMap((state) => {
          if (state === 'enter') {
            return of(false);
          }
          return this.withCounting$;
        }),
        shareReplay(1)
      );
    })
  );
  // this delay is used to make sure the transition from width:0% to width:100% is done after we set up the CSS transition
  littleAnimationDelay$ = this.animating$.pipe(
    debounceTime(10),
    shareReplay(1)
  );
  autoDismissTrigger$ = combineLatest([
    this.animating$,
    this.autoDismissSubject,
  ]).pipe(
    switchMap(([animating, autoDismiss]) => {
      if (autoDismiss === false) {
        return NEVER;
      }
      if (!animating) {
        return NEVER;
      }
      return timer(autoDismiss).pipe(tap(() => this.dismiss.emit()));
    }),
    shareReplay(1)
  );

  constructor(private elementRef: ElementRef<HTMLElement>) {}
}
