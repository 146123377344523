import { Primitive } from '@trp/shared/util';

export function getPropertyValue(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  communityInfo: any,
  property: string,
  section?: string
): Primitive {
  if (section && communityInfo[section]) {
    for (const item of communityInfo[section]) {
      const value = getPropertyValue(item, property);
      if (value !== null) {
        return value;
      }
    }
  } else if (Object.prototype.hasOwnProperty.call(communityInfo, property)) {
    return communityInfo[property];
  }

  return null;
}
