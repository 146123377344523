import { InjectionToken } from '@angular/core';

export const LOADER_OPTIONS = new InjectionToken<LoaderOptions>(
  'LOADER_OPTIONS'
);

export interface LoaderFetchOptions {
  get: boolean;
  post: boolean;
  put: boolean;
  delete: boolean;
}

export interface LoaderOptions {
  fetch: LoaderFetchOptions;
}
