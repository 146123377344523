import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './base-layout.component';
import { ContentScrolledDirective } from './content-scrolled/content-scrolled.directive';

@NgModule({
  declarations: [BaseLayoutComponent, ContentScrolledDirective],
  imports: [CommonModule],
  exports: [BaseLayoutComponent],
})
export class BaseLayoutModule {}
