import { JsonPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { CommunityItem } from '@trp/community/interfaces';
import { TL8Module } from 'tl8';

@Component({
  selector: 'trp-community-expandable-card',
  standalone: true,
  imports: [NgIf, TL8Module, MatExpansionModule, NgTemplateOutlet, JsonPipe],
  templateUrl: './community-expandable-card.component.html',
  styleUrls: ['./community-expandable-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityExpandableCardComponent {
  @Input() item!: CommunityItem;
  @ViewChild(MatExpansionPanel, { static: true })
  expansionPanelEl: MatExpansionPanel | null = null;

  openPanel() {
    if (this.expansionPanelEl) {
      this.expansionPanelEl.open();
    }
  }
}
