<ng-container *ngIf="withCounting$ | async">
  <div
    class="progress"
    *ngIf="animating$ | async"
    [style.transition-duration.ms]="transitionDuration$ | async"
    [class.animated]="littleAnimationDelay$ | async"
  ></div>
</ng-container>

<div class="icon-container">
  <ng-content select="[banner-icon]"></ng-content>
</div>

<p class="content">
  <ng-content></ng-content>
</p>

<div class="space"></div>

<div class="banner-action">
  <ng-content select="[banner-action]"></ng-content>
  <ng-content select="[trpBannerAction]"></ng-content>
</div>

<button
  class="close-button"
  *ngIf="dismissable"
  mat-button
  (click)="dismiss.emit()"
>
  <mat-icon>close</mat-icon>
</button>

<!-- Here we do that to subscribe and start the whole feature -->
<ng-container *ngIf="autoDismissTrigger$ | async"></ng-container>
