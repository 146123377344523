import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleDirective } from './page-title.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PageTitleDirective],
  exports: [PageTitleDirective],
})
export class SharedUiPageTitleModule {}
