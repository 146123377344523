import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { distinctUntilChanged, map } from 'rxjs';

export interface CollapsibleMenuState {
  isCollapsed: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CollapsibleMenuService {
  private store = new ComponentStore<CollapsibleMenuState>({
    isCollapsed: false,
  });

  readonly isCollapsed$ = this.store
    .select((state) => state.isCollapsed)
    .pipe(distinctUntilChanged());
  readonly isExpanded$ = this.isCollapsed$.pipe(
    map((isCollapsed) => !isCollapsed)
  );
  readonly change$ = this.isCollapsed$.pipe(map(() => void 0));

  expand() {
    this.store.setState({ isCollapsed: false });
  }

  collapse() {
    this.store.setState({ isCollapsed: true });
  }

  toggle() {
    this.store.setState((state) => ({ isCollapsed: !state.isCollapsed }));
  }
}
