import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export class LocalTranslateLoader extends TranslateLoader {
  constructor(private langModule: Record<string, unknown>) {
    super();
  }

  getTranslation(lang: string): Observable<Record<string, unknown>> {
    if (lang === 'en') {
      return of(this.langModule);
    }
    return of(this.langModule);
  }
}

export function LoaderFactory(
  langModule: Record<string, unknown>
): TranslateLoader {
  return new LocalTranslateLoader(langModule);
}
