import {
  CommonModule,
  CurrencyPipe,
  DecimalPipe,
  PercentPipe,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { ContainersPipe } from './containers.pipe';
import { PopulationCategory } from './population-category.pipe';
import { ProgramTypePipe } from './program-type.pipe';
import { SafePipe } from './safe.pipe';
import { CurbsideContaminationPipe } from './curbside-contamination.pipe';

@NgModule({
  declarations: [PopulationCategory, SafePipe],
  imports: [
    CommonModule,
    ProgramTypePipe,
    ContainersPipe,
    CurbsideContaminationPipe,
  ],
  providers: [
    ContainersPipe,
    DecimalPipe,
    CurrencyPipe,
    PercentPipe,
    CurbsideContaminationPipe,
  ],
  exports: [
    PopulationCategory,
    SafePipe,
    ContainersPipe,
    ProgramTypePipe,
    CurbsideContaminationPipe,
  ],
})
export class PipesModule {}
