/**
 * Enable GTag tracking only at the provided hostname. You must import the gtag script from your index.html page.
 * @param params the tag ID and the hostname where it should run
 */
export function enableGtagOnProdOnly(params: {
  ids: {
    'gtm.js': string;
    'gtag/js': string;
  };
  onlyForHostname: string;
}) {
  if (!window.location.hostname.includes(params.onlyForHostname)) {
    return;
  }

  gtmStartUp(params.ids['gtm.js']);
  gtagJsStartUp(params.ids['gtag/js']);
}

function gtmStartUp(id: string) {
  const dataLayer = initializeAndGetDataLayer();
  dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  createScriptElementAndInsertBeforeFirst(
    'https://www.googletagmanager.com/gtm.js?id=' + id
  );
}

function gtagJsStartUp(id: string) {
  const dataLayer = initializeAndGetDataLayer();
  dataLayer.push(['js', new Date()]);
  dataLayer.push(['config', id]);
  createScriptElementAndInsertBeforeFirst(
    'https://www.googletagmanager.com/gtag/js?id=' + id
  );
}

function createScriptElementAndInsertBeforeFirst(src: string) {
  const firstScript = document.getElementsByTagName('script')[0];
  const newScript = document.createElement('script');
  newScript.async = true;
  newScript.src = src;
  firstScript.parentNode?.insertBefore(newScript, firstScript);
}

function initializeAndGetDataLayer() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)['dataLayer'] = (window as any)['dataLayer'] || [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any)['dataLayer'] as any[];
}
