import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '@trp/community/notification/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiClient {
  constructor(private httpClient: HttpClient) {}

  getAll() {
    return this.httpClient.get<Notification[]>('/notification');
  }

  dismissNotification(notificationId: Notification['id']) {
    return this.httpClient.patch<Notification>(
      '/notification/' + notificationId,
      { dismissed: true }
    );
  }
}
