<section class="topbar-wrapper" [class.shadow]="scroll.isScrolled$ | async">
  <ng-content select="[trp-layout-topbar]"></ng-content>
</section>
<div class="main-content">
  <aside class="sidebar-wrapper" [class.fit]="fitContent">
    <ng-content select="[trp-layout-sidebar]"></ng-content>
  </aside>
  <section
    class="content-wrapper"
    trpContentScrolled
    #scroll="scroll"
    [class.fit]="fitContent"
  >
    <ng-content select="[trp-layout-content]"></ng-content>
  </section>
</div>
