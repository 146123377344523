import {
  SectionProperties,
  sectionVerifiablePropertiesMap,
} from '@trp/community/interfaces';
import { ProgramVerification } from '@trp/community/program-verification/interfaces';
import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';
import { getSectionVerificationStatus } from './get-section-verification-status';

export function getProgramVerifiedByInformation<
  T extends Pick<
    ProgramVerification,
    | 'property'
    | 'verifiedValue'
    | 'createdAt'
    | 'verifiedByUser'
    | 'programOrSectionType'
  >
>(communityInformation: PrivateCommunityInformation, verifications: T[]) {
  const verifiedStatusBySection = (
    Object.keys(
      sectionVerifiablePropertiesMap
    ) as (keyof typeof sectionVerifiablePropertiesMap)[]
  ).map((programOrSectionType) => {
    return getSectionVerificationStatus(
      programOrSectionType,
      communityInformation,
      verifications.filter(
        (item) => item.programOrSectionType === programOrSectionType
      ),
      sectionVerifiablePropertiesMap[programOrSectionType]
    );
  });

  const isVerified = verifiedStatusBySection.every(
    (item) => item === 'verified'
  );
  if (!isVerified) {
    return null;
  }

  const latestVerification = verifications
    .filter((v) =>
      sectionVerifiablePropertiesMap[v.programOrSectionType].includes(
        v.property as SectionProperties<typeof v.programOrSectionType>
      )
    )
    .reduce((prev, curr) => {
      if (!prev) {
        return curr;
      }
      if (new Date(prev.createdAt) < new Date(curr.createdAt)) {
        return curr;
      }
      return prev;
    }, null as T | null);
  if (!latestVerification) {
    return null;
  }
  return {
    verifiedBy: latestVerification.verifiedByUser,
    verifiedAt: latestVerification.createdAt,
  };
}
