import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuItem, MENU_CONFIG } from '../../menu.config';

@Component({
  selector: 'community-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent {
  items = MENU_CONFIG.filter((item) => !item.onlyVerified);

  getNavLinkClass(item: MenuItem) {
    if (item.external) {
      return ['nav-link', 'external-link'];
    }

    return ['nav-link'];
  }
}
