import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { MaterialGroup, RecyclingCategory } from '@trp/nationaldb/interfaces';
import {
  Observable,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  retry,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecyclingCategoriesDataAccessClient {
  private store = new ComponentStore<{
    recyclingCategories: RecyclingCategory[] | null;
  }>({ recyclingCategories: null });

  definedRecyclingCategories$ = this.store
    .select((s) => s.recyclingCategories)
    .pipe(
      distinctUntilChanged(),
      filter(
        (categories): categories is RecyclingCategory[] => categories !== null
      )
    );

  fetch = this.store.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() =>
        this.httpClient.get<RecyclingCategory[]>('/recycling-categories').pipe(
          retry({
            delay: 2000,
          }),
          tap((recyclingCategories) => {
            this.setRecyclingCategories(recyclingCategories);
          })
        )
      )
    )
  );

  private setRecyclingCategories = this.store.updater(
    (state, recyclingCategories: RecyclingCategory[]) => ({
      ...state,
      recyclingCategories,
    })
  );

  constructor(private httpClient: HttpClient) {}

  initialize() {
    this.fetch();
    return firstValueFrom(this.definedRecyclingCategories$);
  }

  groupMaterialsByCategory$(materials: number[]): Observable<MaterialGroup[]> {
    return this.definedRecyclingCategories$.pipe(
      map((recyclingCategories) => {
        let materialGroups = materials.reduce((prev, curr) => {
          const category = recyclingCategories.find((c) => c.id === curr);
          if (!category) {
            // console.warn('Recycling category not found for id ' + curr);
            return prev;
          }
          const existingMaterialGroup = prev.find(
            (p) => p.material === category.material
          );
          if (!existingMaterialGroup) {
            const newMaterialGroup: MaterialGroup = {
              material: category.material,
              categories: [
                {
                  name: category.subcategory || '',
                  items: [category],
                },
              ],
            };
            return [...prev, newMaterialGroup];
          }
          const existingSubcategoryGroup =
            existingMaterialGroup.categories.find(
              (c) => c.name === (category.subcategory || '')
            );
          if (!existingSubcategoryGroup) {
            existingMaterialGroup.categories.push({
              name: category.subcategory || '',
              items: [category],
            });
            existingMaterialGroup.categories.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
          } else {
            existingSubcategoryGroup.items.push(category);
            existingSubcategoryGroup.items.sort((a, b) =>
              a.category.localeCompare(b.category)
            );
          }
          return prev;
        }, [] as MaterialGroup[]);

        materialGroups = materialGroups.map((item) => {
          // Move Other Plastic to the bottom of the list
          if (item.material === 'Plastic') {
            const found = item.categories.find(
              (c) => c.name === 'Other Plastic'
            );

            if (found) {
              item.categories = [
                ...item.categories.filter((c) => c.name !== 'Other Plastic'),
                found,
              ];
            }
          }

          // Remove category name for Metal and Glass
          if (item.material === 'Metal' || item.material === 'Glass') {
            item.categories = item.categories.map((category) => ({
              ...category,
              name: '',
            }));
          }

          return item;
        });

        return materialGroups;
      })
    );
  }
}
