import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  CommunityProfileInfoHost,
  CommunityProfileSections,
  CommunityProfileWidgetDefinition,
} from '@trp/community/interfaces';
import { PipesModule } from '@trp/community/shared/ui';
import {
  PrivateCommunityInformation,
  PublicCommunityInformation,
} from '@trp/nationaldb/interfaces';
import { TL8Module } from 'tl8';
import { CommunityWidgetComponent } from '../community-widget';
import { CommunityWidgetContentComponent } from '../community-widget-content';
import { CommunityWidgetHeaderComponent } from '../community-widget-header';

@Component({
  selector: 'trp-standard-widget',
  standalone: true,
  imports: [
    CommonModule,
    TL8Module,
    CommunityWidgetHeaderComponent,
    CommunityWidgetComponent,
    CommunityWidgetContentComponent,
    PipesModule,
  ],
  templateUrl: './standard-widget.component.html',
  styleUrls: ['./standard-widget.component.scss'],
})
export class StandardWidgetComponent<
  TSection extends CommunityProfileSections
> {
  @Input() widgetDefinition!: CommunityProfileWidgetDefinition<TSection>;
  @Input() sectionCommunityInfo!: CommunityProfileInfoHost[TSection];
  @Input() communityInfo!:
    | PrivateCommunityInformation
    | PublicCommunityInformation;
}
