import { APP_INITIALIZER } from '@angular/core';
import { RecyclingCategoriesDataAccessClient } from '@trp/community/recycling-categories/data-access-client';

function loadRecyclingCategories(
  recyclingCategoriesDataAccessClient: RecyclingCategoriesDataAccessClient
) {
  return () => recyclingCategoriesDataAccessClient.initialize();
}

export const provideRecyclingCategories = () => [
  {
    provide: APP_INITIALIZER,
    deps: [RecyclingCategoriesDataAccessClient],
    useFactory: loadRecyclingCategories,
    multi: true,
  },
];
