<ng-container *ngIf="notification">
  <trp-banner [dismissable]="false" [bannerType]="'info'">
    <ng-container banner-content>
      {{
        'banner.verifyEmail.text' | tl8 : { email: notification.content.email }
      }}
    </ng-container>

    <button
      trpBannerAction
      mat-button
      mat-stroked-button
      (click)="sendVerificationEmail({ error: error, success: success })"
      [disabled]="state === 'pending'"
    >
      <ng-container *ngIf="state === 'idle'">
        {{ 'banner.verifyEmail.sendEmail' | tl8 }}
      </ng-container>
      <ng-container *ngIf="state === 'pending'">
        {{ 'banner.verifyEmail.sendingEmail' | tl8 }}
      </ng-container>
    </button>
  </trp-banner>

  <ng-template #error
    ><mat-icon>warning</mat-icon>
    {{ 'banner.verifyEmail.error' | tl8 }}</ng-template
  >
  <ng-template #success
    ><mat-icon>check</mat-icon>
    {{
      'banner.verifyEmail.success' | tl8 : { email: notification.content.email }
    }}</ng-template
  >
</ng-container>
