export interface AccessRequestCreatedNotificationContent {
  community: {
    id: string;
    name: string;
  };
}

export interface AccessRequestApprovedNotificationContent {
  community: {
    id: string;
    name: string;
  };
}

export interface AccessRequestRejectedNotificationContent {
  community: {
    id: string;
    name: string;
  };
}

export const NOTIFICATIONS = {
  accessRequestCreated: 'access-request.created',
  accessRequestApproved: 'access-request.approved',
  accessRequestRejected: 'access-request.rejected',
};

export type AccessRequestStatus = 'none' | 'pending' | 'accepted';
export interface AccessRequestStatusResponse {
  status: AccessRequestStatus;
}
