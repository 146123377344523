import { HttpContext, HttpContextToken } from '@angular/common/http';

export const LOADER_HTTP_CONTEXT_TOKEN_DEFAULT = undefined;

export const LOADER_HTTP_CONTEXT_TOKEN = new HttpContextToken(
  () => LOADER_HTTP_CONTEXT_TOKEN_DEFAULT
);

export function LoaderHttpContext(context?: HttpContext) {
  return (context ?? new HttpContext()).set(LOADER_HTTP_CONTEXT_TOKEN, true);
}
